#skills-section {
  background-color: #192937;

  .ant-typography, .ant-divider-inner-text {
    color: #eee;
  }

  .skills-code-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0; 
    .skills-code-image {
      height: 400px;
      width: 400px; 
      background-color: red;
    }
    .skills-code-text {
      width: 50%;
    }
  }

}