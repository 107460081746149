.menuBar{
  width:100%;
  padding: 0 20px;
  overflow: auto;
  position: fixed;
  z-index: 500;
  transition: all 0.25s linear;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: 1s linear fadeIn; 
}

.scrolled-menubar {
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 42px -20px rgba(25,41,55,0.75);
  -moz-box-shadow: 0px 0px 42px -20px rgba(25,41,55,0.75);
  box-shadow: 0px 0px 42px -20px rgba(25,41,55,0.75);

  .logo {
    opacity: 1;
  }
  .menuCon .ant-menu-submenu-title{
    color: #192937;
    opacity: 0.75;
  }
  .menuCon .ant-menu-item a, .menuCon .ant-menu-submenu-title a{
    color: #192937;
    opacity: 0.75;
  }

  .barsMenu{
    color: #192937;
    opacity: 0.75;
  }
}

.ant-menu-submenu .demos {
  .demo-link {
    opacity: 0.75 !important;
    color: #192937 !important;
  }
  .demo-link:hover {
    opacity: 1 !important;
    color: #009688 !important;
  }
}

.ant-menu-item, .ant-menu-submenu-horizontal {
  border-bottom: none !important;
  position: relative;
  margin-top: 2.5px !important;

  .nav-background-circle {
    height: 69px;
    width: 69px;
    background-color: #009688;
    border-radius: 100%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -2.5px;
    z-index: -1;
    transform: scale(0);
    transition: transform 0.2s linear;
  }

  .nav-demos-item, .ant-menu-submenu-title, a {
    transition: all 0.4s linear;
  }
}

.ant-menu-item:hover, .ant-menu-submenu:hover {
  .nav-background-circle {
    transform: scale(1)
  }
  
  .nav-demos-item, .ant-menu-submenu-title, a {
    opacity: 1 !important;
    color: #eee !important;
  }
}

.logo{
  width: 150px;
  float: left;
  opacity: 0.5;
  transition: opacity 0.25s linear;
}

.logo:hover {
  opacity: 1;
}

.logo a{
  display: inline-block;
  font-size: 22px;
  padding: 19px 20px;
  text-transform: capitalize;
  font-weight: bold;
}
.menuCon{
  width: calc(100% - 150px);
  float: left;
}
.menuCon .ant-menu-item{
  padding: 0px 5px;
}
.menuCon .ant-menu-submenu-title{
  padding: 10px 20px;
  color: #eee;
  opacity: 0.5;
}
.menuCon .ant-menu-item a, .menuCon .ant-menu-submenu-title a{
  padding: 10px 15px;
  color: #eee;
  opacity: 0.5;
}
.menuCon .ant-menu-horizontal{
  border-bottom: none;
  background-color: unset;
}
.menuCon .rightMenu{
  float: right;
}
.barsMenu{
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background-color: unset !important;
}
.ant-drawer-body{
  padding: 0;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item, .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu{
  display: inline-block;
  width: 100%;
}
.ant-drawer-body .ant-menu-horizontal{
  border-bottom: none;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover{
  border-bottom-color: transparent;
}

@media(max-width: 767px){
  .barsMenu{
    display: inline-block;
    border: none;
    color: #eee;
    font-size: 1.25em;
    opacity: 0.5;
    box-shadow: none;
  }
  .rightMenu{
    display: none;
  }
  .logo a{
    margin-left: -20px;
  }
  .menuCon .ant-menu-item, .menuCon .ant-menu-submenu-title{
    padding: 1px 20px;
  }
  .logo a{
    padding: 10px 20px;
  }
}