.project {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  height: 400px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(25,41,55,0.25);
  -moz-box-shadow: 0px 0px 20px 0px rgba(25,41,55,0.25);
  box-shadow: 0px 0px 20px 0px rgba(25,41,55,0.25);
  margin: 25px 0;

  .project-background {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 300px;
    border-radius: 100%;
    background-repeat: none;
    background-position: center;
    background-size: cover;

    .project-icon {
      height: 100px;
      width: 100px;
      border-radius: 20px;
    }
  }

  .project-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;

    .download {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .storeLinkApple {
        height: 75px;
        width: auto;
        // Adjust for margin on google play logo
        margin: calc(35px / 2) 0;
      }
      
      .storeLinkGoogle {
        height: 110px;
        width: auto;
      }
         
      @media only screen and (max-width: 992px) {
        .storeLinkApple {
          height: 50px;
          // Adjust for margin on google play logo
          margin: calc(25px / 2) 0;
        }
        
        .storeLinkGoogle {
          height: 75px;
        } 
      }
    }

    @media only screen and (max-width: 992px) {
      width: 50%;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column; 
    align-items: center;
    height: unset;

    .project-info {
      margin-top: 20px;
      width: 90%;
    }
  }

  @media only screen and (max-width: 400px) {
    .project-background {
      width: 200px; 
      height: 200px;
      .project-icon {
        width: 75px;
        height: 75px;
      }
    }

    .project-info {
      h2 {
        font-size: 1.75em;
      }
    }
  }
}