.form-wrapper {
  margin: 50px auto;
  position: relative;
  .contact-form-bubble-large {
    position: absolute; 
    background-color: #009688; 
    height: 450px; 
    width: 450px;
    border-radius: 100%;
    top: -2.5vh;
  }
  .contact-form-bubble-small {
    position: absolute; 
    background-color: rgba(1,181,164,1); 
    height: 300px; 
    width: 300px;
    border-radius: 100%;
    right: 0;
    bottom: -2.5vh;
  }

  .custom-form {
    padding: 25px;
    min-height: 350px; 
    max-width: 500px;
    margin: auto;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(25,41,55,0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(25,41,55,0.25);
    box-shadow: 0px 0px 15px 0px rgba(25,41,55,0.25);
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .ant-form-item:last-of-type {
      display: flex;
      justify-content: center;
      .custom-form-button {
        color: #eee;
        background: rgb(1,181,164);
        background: linear-gradient(90deg, rgba(1,181,164,1) 0%, rgba(0,150,136,1) 91%);
        border: none;
        padding: 0 35px;
        margin-top: 25px;
      }
    }

    .custom-form-button:hover {
      .anticon-upload {
        animation: uploadIcon 0.5s linear;
      }
    }

    .custom-submission-confirmation {
      position: absolute;
      opacity: 0.5;
      text-align: center;
      transform: scale(0, 0);
      transition: transform 0.25s linear;
    }
  
    .custom-submission-confirmation-show {
      position: unset;
      transform: scale(1, 1)
    }
  
    .custom-submission-confirmation-fail {
      position: unset;
      transform: scale(1, 1)
    }
  
  }

  @media screen and (max-width: 768px) {
    .contact-form-bubble-small, .contact-form-bubble-large {
      display: none;
    }
  }
}

@keyframes uploadIcon {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(0)
  }
  50% {
    transform: translateY(5px);
  }
  65% {
    transform: translateY(-2px);
  }
  85% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0)
  }
}