#about-section {
  #about-me-image {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 40px;

    #me-image-group {
      position: relative;
      #me-image-background-bubble {
        height: 225px;
        width: 225px; 
        background-color: #009688;
        border-radius: 100%;
      }
      #me-image {
        position: absolute;
        z-index: 5;
        height: 200px;
        width: 200px; 
        border-radius: 100pt;
        top: 10%;
        left: 25%;
      }
      #me-image-foreground-bubble {
        position: absolute;
        z-index: 6;
        height: 75px;
        width: 75px; 
        background-color: #eee;
        border-radius: 100pt;
        top: 75%;
        left: 100%;
      }
    }

    #me-arrow {
      font-size: 2em;
      position: absolute;
      right: 0;
      color: #009688;
    }
  }
}