@import url(https://fonts.googleapis.com/css?family=Raleway:400,600&display=swap);
html, body {
  font-family: 'Raleway', sans-serif; }

.menuBar {
  width: 100%;
  padding: 0 20px;
  overflow: auto;
  position: fixed;
  z-index: 500;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  -webkit-animation: 1s linear fadeIn;
          animation: 1s linear fadeIn; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.scrolled-menubar {
  background-color: #fff;
  box-shadow: 0px 0px 42px -20px rgba(25, 41, 55, 0.75); }
  .scrolled-menubar .logo {
    opacity: 1; }
  .scrolled-menubar .menuCon .ant-menu-submenu-title {
    color: #192937;
    opacity: 0.75; }
  .scrolled-menubar .menuCon .ant-menu-item a, .scrolled-menubar .menuCon .ant-menu-submenu-title a {
    color: #192937;
    opacity: 0.75; }
  .scrolled-menubar .barsMenu {
    color: #192937;
    opacity: 0.75; }

.ant-menu-submenu .demos .demo-link {
  opacity: 0.75 !important;
  color: #192937 !important; }

.ant-menu-submenu .demos .demo-link:hover {
  opacity: 1 !important;
  color: #009688 !important; }

.ant-menu-item, .ant-menu-submenu-horizontal {
  border-bottom: none !important;
  position: relative;
  margin-top: 2.5px !important; }
  .ant-menu-item .nav-background-circle, .ant-menu-submenu-horizontal .nav-background-circle {
    height: 69px;
    width: 69px;
    background-color: #009688;
    border-radius: 100%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -2.5px;
    z-index: -1;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: -webkit-transform 0.2s linear;
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear; }
  .ant-menu-item .nav-demos-item, .ant-menu-item .ant-menu-submenu-title, .ant-menu-item a, .ant-menu-submenu-horizontal .nav-demos-item, .ant-menu-submenu-horizontal .ant-menu-submenu-title, .ant-menu-submenu-horizontal a {
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear; }

.ant-menu-item:hover .nav-background-circle, .ant-menu-submenu:hover .nav-background-circle {
  -webkit-transform: scale(1);
          transform: scale(1); }

.ant-menu-item:hover .nav-demos-item, .ant-menu-item:hover .ant-menu-submenu-title, .ant-menu-item:hover a, .ant-menu-submenu:hover .nav-demos-item, .ant-menu-submenu:hover .ant-menu-submenu-title, .ant-menu-submenu:hover a {
  opacity: 1 !important;
  color: #eee !important; }

.logo {
  width: 150px;
  float: left;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s linear;
  transition: opacity 0.25s linear; }

.logo:hover {
  opacity: 1; }

.logo a {
  display: inline-block;
  font-size: 22px;
  padding: 19px 20px;
  text-transform: capitalize;
  font-weight: bold; }

.menuCon {
  width: calc(100% - 150px);
  float: left; }

.menuCon .ant-menu-item {
  padding: 0px 5px; }

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
  color: #eee;
  opacity: 0.5; }

.menuCon .ant-menu-item a, .menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
  color: #eee;
  opacity: 0.5; }

.menuCon .ant-menu-horizontal {
  border-bottom: none;
  background-color: unset; }

.menuCon .rightMenu {
  float: right; }

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background-color: unset !important; }

.ant-drawer-body {
  padding: 0; }

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item, .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%; }

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none; }

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent; }

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
    border: none;
    color: #eee;
    font-size: 1.25em;
    opacity: 0.5;
    box-shadow: none; }
  .rightMenu {
    display: none; }
  .logo a {
    margin-left: -20px; }
  .menuCon .ant-menu-item, .menuCon .ant-menu-submenu-title {
    padding: 1px 20px; }
  .logo a {
    padding: 10px 20px; } }

.container {
  margin: 0 auto;
  width: 95vw;
  max-width: 1000px; }
  .container.text-container {
    max-width: 750px; }

#footer {
  position: relative;
  width: 100%;
  height: 500px;
  background-color: #192937;
  color: #eee !important;
  overflow: hidden;
  padding: 50px 0; }
  #footer .container {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    #footer .container .ant-typography {
      color: #eee !important; }
    #footer .container #footer-content {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      z-index: 50; }
      #footer .container #footer-content #footer-contact-info {
        width: 50%; }
      #footer .container #footer-content #footer-social {
        z-index: 1;
        width: 175px;
        text-align: center; }
        #footer .container #footer-content #footer-social #footer-social-buttons {
          display: flex;
          justify-content: space-evenly; }
        #footer .container #footer-content #footer-social #footer-social-buttons i {
          cursor: pointer; }
        #footer .container #footer-content #footer-social #footer-social-buttons i:hover {
          color: #009688;
          -webkit-animation: footer-social-hover 0.75s ease-in-out;
                  animation: footer-social-hover 0.75s ease-in-out; }
  #footer #footer-bubble {
    position: absolute;
    height: 40vw;
    width: 40vw;
    max-height: 500px;
    max-width: 500px;
    background-color: #009688;
    border-radius: 100%;
    right: 10vw;
    top: -10vw; }
  #footer #small-footer-bubble {
    position: absolute;
    height: 25vw;
    width: 25vw;
    max-height: 300px;
    max-width: 300px;
    background-color: #eee;
    border-radius: 100%;
    right: 5vw;
    top: 0; }
  #footer .copyright {
    opacity: 0.5;
    width: 100%;
    text-align: center;
    margin-top: 15px; }

@-webkit-keyframes footer-social-hover {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  25% {
    -webkit-transform: rotateZ(20deg);
            transform: rotateZ(20deg); }
  50% {
    -webkit-transform: rotateZ(-15deg);
            transform: rotateZ(-15deg); }
  75% {
    -webkit-transform: rotateZ(10deg);
            transform: rotateZ(10deg); }
  100% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); } }

@keyframes footer-social-hover {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  25% {
    -webkit-transform: rotateZ(20deg);
            transform: rotateZ(20deg); }
  50% {
    -webkit-transform: rotateZ(-15deg);
            transform: rotateZ(-15deg); }
  75% {
    -webkit-transform: rotateZ(10deg);
            transform: rotateZ(10deg); }
  100% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); } }

.sub-page-wrapper .page-header {
  background-color: #192937;
  overflow: hidden;
  position: relative; }
  .sub-page-wrapper .page-header h1 {
    color: #eee;
    padding: 100px 0;
    width: 100%;
    text-align: center;
    font-size: 4em;
    z-index: 10;
    position: relative; }
  .sub-page-wrapper .page-header #header-large-bubble {
    position: absolute;
    height: 400px;
    width: 400px;
    background-color: #009688;
    border-radius: 100%;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    left: 45%; }
  .sub-page-wrapper .page-header #header-small-bubble {
    position: absolute;
    height: 200px;
    width: 200px;
    background-color: #eee;
    border-radius: 100%;
    right: 50%;
    bottom: -75px; }

.custom-button-hover {
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease; }

.custom-button-hover:hover {
  box-shadow: 1px 1px #009688, 2px 2px #009688, 3px 3px #009688;
  -webkit-transform: translateX(-3px);
  transform: translate(-3px, -1px); }

.ant-btn-primary.custom-button-hover:hover {
  box-shadow: 1px 1px #eee, 2px 2px #eee, 3px 3px #eee; }

.section {
  padding: 50px 0; }

#about-section #about-me-image {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 40px; }
  #about-section #about-me-image #me-image-group {
    position: relative; }
    #about-section #about-me-image #me-image-group #me-image-background-bubble {
      height: 225px;
      width: 225px;
      background-color: #009688;
      border-radius: 100%; }
    #about-section #about-me-image #me-image-group #me-image {
      position: absolute;
      z-index: 5;
      height: 200px;
      width: 200px;
      border-radius: 100pt;
      top: 10%;
      left: 25%; }
    #about-section #about-me-image #me-image-group #me-image-foreground-bubble {
      position: absolute;
      z-index: 6;
      height: 75px;
      width: 75px;
      background-color: #eee;
      border-radius: 100pt;
      top: 75%;
      left: 100%; }
  #about-section #about-me-image #me-arrow {
    font-size: 2em;
    position: absolute;
    right: 0;
    color: #009688; }

#skills-section {
  background-color: #192937; }
  #skills-section .ant-typography, #skills-section .ant-divider-inner-text {
    color: #eee; }
  #skills-section .skills-code-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0; }
    #skills-section .skills-code-wrapper .skills-code-image {
      height: 400px;
      width: 400px;
      background-color: red; }
    #skills-section .skills-code-wrapper .skills-code-text {
      width: 50%; }

.form-wrapper {
  margin: 50px auto;
  position: relative; }
  .form-wrapper .contact-form-bubble-large {
    position: absolute;
    background-color: #009688;
    height: 450px;
    width: 450px;
    border-radius: 100%;
    top: -2.5vh; }
  .form-wrapper .contact-form-bubble-small {
    position: absolute;
    background-color: #01b5a4;
    height: 300px;
    width: 300px;
    border-radius: 100%;
    right: 0;
    bottom: -2.5vh; }
  .form-wrapper .custom-form {
    padding: 25px;
    min-height: 350px;
    max-width: 500px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(25, 41, 55, 0.25);
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; }
    .form-wrapper .custom-form .ant-form-item:last-of-type {
      display: flex;
      justify-content: center; }
      .form-wrapper .custom-form .ant-form-item:last-of-type .custom-form-button {
        color: #eee;
        background: #01b5a4;
        background: -webkit-gradient(linear, left top, right top, from(#01b5a4), color-stop(91%, #009688));
        background: -webkit-linear-gradient(left, #01b5a4 0%, #009688 91%);
        background: linear-gradient(90deg, #01b5a4 0%, #009688 91%);
        border: none;
        padding: 0 35px;
        margin-top: 25px; }
    .form-wrapper .custom-form .custom-form-button:hover .anticon-upload {
      -webkit-animation: uploadIcon 0.5s linear;
              animation: uploadIcon 0.5s linear; }
    .form-wrapper .custom-form .custom-submission-confirmation {
      position: absolute;
      opacity: 0.5;
      text-align: center;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.25s linear;
      transition: -webkit-transform 0.25s linear;
      transition: transform 0.25s linear;
      transition: transform 0.25s linear, -webkit-transform 0.25s linear; }
    .form-wrapper .custom-form .custom-submission-confirmation-show {
      position: unset;
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .form-wrapper .custom-form .custom-submission-confirmation-fail {
      position: unset;
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
  @media screen and (max-width: 768px) {
    .form-wrapper .contact-form-bubble-small, .form-wrapper .contact-form-bubble-large {
      display: none; } }

@-webkit-keyframes uploadIcon {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  25% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  65% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px); }
  85% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes uploadIcon {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  25% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  65% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px); }
  85% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

#contact {
  position: relative; }

#landing-anim {
  background-color: #192937;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center; }
  #landing-anim .p5Canvas {
    -webkit-animation: 1s linear fadeIn;
            animation: 1s linear fadeIn; }
  #landing-anim #landing-anim-me {
    -webkit-animation: 1.75s linear fadeIn;
            animation: 1.75s linear fadeIn;
    position: absolute;
    top: 15vh;
    text-align: center; }
    #landing-anim #landing-anim-me h1, #landing-anim #landing-anim-me h2 {
      color: #eee; }
      #landing-anim #landing-anim-me h1 span, #landing-anim #landing-anim-me h2 span {
        color: #009688;
        font-weight: bold; }
      @media screen and (max-width: 600px) {
        #landing-anim #landing-anim-me h1, #landing-anim #landing-anim-me h2 {
          font-size: 35px;
          margin-left: 10px;
          margin-right: 10px; } }
  #landing-anim #landing-anim-actions {
    -webkit-animation: 2.5s linear fadeIn;
            animation: 2.5s linear fadeIn;
    height: 100px;
    position: absolute;
    bottom: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between; }
    #landing-anim #landing-anim-actions div button {
      margin: 5px 25px; }
    #landing-anim #landing-anim-actions div .download-resume:hover i {
      -webkit-animation: downloadIcon 0.75s linear;
              animation: downloadIcon 0.75s linear; }
    #landing-anim #landing-anim-actions div .view-my-work:hover i {
      -webkit-animation: viewMyWorkIcon 0.75s linear;
              animation: viewMyWorkIcon 0.75s linear; }
    #landing-anim #landing-anim-actions p {
      color: #eee;
      opacity: 0.5;
      font-size: 0.9em; }
      #landing-anim #landing-anim-actions p span {
        color: #009688;
        cursor: pointer; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes downloadIcon {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  25% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  65% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px); }
  85% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes downloadIcon {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  25% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  65% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px); }
  85% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes viewMyWorkIcon {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  25% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  65% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px); }
  85% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes viewMyWorkIcon {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  25% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  65% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px); }
  85% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.project {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  height: 400px;
  box-shadow: 0px 0px 20px 0px rgba(25, 41, 55, 0.25);
  margin: 25px 0; }
  .project .project-background {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 300px;
    border-radius: 100%;
    background-repeat: none;
    background-position: center;
    background-size: cover; }
    .project .project-background .project-icon {
      height: 100px;
      width: 100px;
      border-radius: 20px; }
  .project .project-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%; }
    .project .project-info .download {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      .project .project-info .download .storeLinkApple {
        height: 75px;
        width: auto;
        margin: calc(35px / 2) 0; }
      .project .project-info .download .storeLinkGoogle {
        height: 110px;
        width: auto; }
      @media only screen and (max-width: 992px) {
        .project .project-info .download .storeLinkApple {
          height: 50px;
          margin: calc(25px / 2) 0; }
        .project .project-info .download .storeLinkGoogle {
          height: 75px; } }
    @media only screen and (max-width: 992px) {
      .project .project-info {
        width: 50%; } }
  @media only screen and (max-width: 768px) {
    .project {
      flex-direction: column;
      align-items: center;
      height: unset; }
      .project .project-info {
        margin-top: 20px;
        width: 90%; } }
  @media only screen and (max-width: 400px) {
    .project .project-background {
      width: 200px;
      height: 200px; }
      .project .project-background .project-icon {
        width: 75px;
        height: 75px; }
    .project .project-info h2 {
      font-size: 1.75em; } }

