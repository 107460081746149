$white: #eee; 
$green: #009688;
$text-color: #192937;
#landing-anim {
  background-color: $text-color;
  height: 100vh;
  width: 100vw; 
  display: flex; 
  flex-direction: column; 
  align-items: center;

  .p5Canvas {
    animation: 1s linear fadeIn;
  }
  
  #landing-anim-me {
    animation: 1.75s linear fadeIn; 
    position: absolute; 
    top: 15vh;
    text-align: center;

    h1, h2 {
      color: $white;
      span {
        color: $green;
        font-weight: bold; 
      }

      @media screen and (max-width: 600px) {
        font-size: 35px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  #landing-anim-actions {
    animation: 2.5s linear fadeIn;
    height: 100px; 
    position: absolute;
    bottom: 10vh;
    display: flex; 
    flex-direction: column;
    align-items: center; 
    text-align: center; 
    justify-content: space-between;

    div {
      button {
        margin: 5px 25px;
      }

      .download-resume:hover {
        i {
          animation: downloadIcon 0.75s linear;
        }
      }

      .view-my-work:hover {
        i {
          animation: viewMyWorkIcon 0.75s linear;
        }
      }
    }
    
    p {
      color: $white;
      opacity: 0.5;
      font-size: 0.9em;

      span {
        color: $green;
        cursor: pointer;
      }
    }
  }
}

// Animations 

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes downloadIcon {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(0)
  }
  50% {
    transform: translateY(5px);
  }
  65% {
    transform: translateY(-2px);
  }
  85% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0)
  }
}

@keyframes viewMyWorkIcon {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0)
  }
  50% {
    transform: translateX(5px);
  }
  65% {
    transform: translateX(-2px);
  }
  85% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0)
  }
}

