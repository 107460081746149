.sub-page-wrapper {
  .page-header {
    background-color: #192937;
    overflow: hidden;
    position: relative;

    h1 {
      color: #eee;
      padding: 100px 0;
      width: 100%;
      text-align: center;
      font-size: 4em;
      z-index: 10;
      position: relative;
    }

    #header-large-bubble {
      position: absolute;
      height: 400px;
      width: 400px;
      background-color: #009688;
      border-radius: 100%;
      top: 0; 
      bottom: 0; 
      margin-top: auto;
      margin-bottom: auto;
      left: 45%;
    }
    #header-small-bubble {
      position: absolute;
      height: 200px;
      width: 200px;
      background-color: #eee;
      border-radius: 100%;
      right: 50%;
      bottom: -75px;
    }
  }
}