#footer {
  position: relative;
  width: 100%; 
  height: 500px;
  background-color: rgb(25, 41, 55);
  color: #eee !important; 
  overflow: hidden;
  padding: 50px 0;
  
  .container {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .ant-typography {
      color: #eee !important; 
    }
  
    #footer-content {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      z-index: 50;

      #footer-contact-info {
        width: 50%;
      }
  
      #footer-social {
        z-index: 1;
        width: 175px;
        text-align: center;

        #footer-social-buttons {
          display: flex;
          justify-content: space-evenly;
        }


        #footer-social-buttons {
          i {
            cursor: pointer;
          }
          i:hover {
            color: #009688;
            animation: footer-social-hover 0.75s ease-in-out;
          }
        }
      }
    }
  }


  #footer-bubble {
    position: absolute;
    height: 40vw;
    width: 40vw;
    max-height: 500px;
    max-width: 500px;
    background-color: #009688;
    border-radius: 100%;
    right: 10vw;
    top: -10vw;
  }
  #small-footer-bubble {
    position: absolute;
    height: 25vw; 
    width: 25vw;
    max-height: 300px;
    max-width: 300px;
    background-color: #eee;
    border-radius: 100%;
    right: 5vw;
    top: 0;
  }

  .copyright {
    opacity: 0.5;
    width: 100%; 
    text-align: center;
    margin-top: 15px;
  }

  @keyframes footer-social-hover {
    0% {
      transform: rotateX(0deg)
    }
    25% {
      transform: rotateZ(20deg)
    }
    50% {
      transform: rotateZ(-15deg)
    }
    75% {
      transform: rotateZ(10deg)
    }
    100% {
      transform: rotateZ(0deg)
    }

  }
}